import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ExteriorLink from "../components/link"

const ImageCrop = styled.div`
  clip-path: circle(80px at center);
  max-width: 300px;
  margin: 6rem 30px 3rem 30px;
`
const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-top: 1rem;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        maxWidth: "600px",
        textAlign: "center",
      }}
    >
      <ImageCrop>
        <Image />
      </ImageCrop>
      <Description>
        Abbey Mondshein is currently crafting code at{" "}
        <ExteriorLink linkTarget="https://work.co" linkCopy="work & co" />.
      </Description>
    </div>
  </Layout>
)

export default IndexPage
